<template>
  <b-modal
    id="position-modal"
    :title="$t('POSITIONS.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex px-3 py-5 mt-5 flex-column">
      <PositionSettingsEditForm></PositionSettingsEditForm>
    </div>
  </b-modal>
</template>

<script>
import PositionSettingsEditForm from "@/modules/setting/components/positions/PositionSettingsEditForm";
export default {
  name: "PositionSettingsModal",
  components: { PositionSettingsEditForm },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
};
</script>

<style scoped></style>
