<template>
  <b-form v-on:submit.prevent="submit">
    <b-form-group :label="$t('DEPARTMENTFORM.NAME')">
      <b-form-input
        id="name"
        class="form-control h-auto"
        v-model="$v.form.name.$model"
        :state="validateState('name')"
        :placeholder="$t('POSITIONS.NAME')"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group :label="$t('JOB.CODE')">
      <b-form-input
        id="code"
        class="form-control h-auto"
        v-model="$v.form.code.$model"
        :state="validateState('code')"
        :placeholder="$t('POSITIONS.CODE')"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group :label="$t('DETAILSFORM.DESC')">
      <b-form-textarea
        id="description"
        max-rows="5"
        rows="2"
        v-model="$v.form.description.$model"
        :state="validateState('description')"
        :placeholder="$t('DETAILSFORM.DESC1')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.description.required">
        Please Fill</b-form-invalid-feedback
      >
      <b-form-invalid-feedback v-if="!$v.form.description.maxLength">
        No more than {{ $v.form.description.$params.maxLength.max }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.description.minLength">
        Must be more than {{ $v.form.description.$params.minLength.min }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-button
      variant="primary"
      type="submit"
      block
      v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
      >{{ $t("COMPANY.REGISTRATION.SUBMIT") }}</b-button
    >
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "PositionSettingsPageForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        code: "",
        description: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      code: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(200),
        minLength: minLength(2),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      const name = this.$v.form.name.$model;
      const code = this.$v.form.code.$model;
      const description = this.$v.form.description.$model;

      const submitButton = this.$refs["department-form-submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      console.log(name);
      console.log(code);
      console.log(description);

      this.isBusy = true;
      const form = form;
      this.$store
        .dispatch("saveCompanyPositionsForm", form)
        .then((value) => console.log(value))
        .catch((reason) => {
          this.$bvModal.msgBoxOk(reason.message, {
            title: "Error on Create",
            size: "sm",
            okVariant: "danger",
            centered: true,
          });
          console.log(reason);
        })
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
