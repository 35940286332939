<template>
  <b-form class="form" v-on:submit.prevent="onSubmit">
    <h4 class="font-weight-bolder mb-5"></h4>

    <b-form-group
      id="name"
      label-cols-lg="2"
      label-for="name"
      :label="$t('DEPARTMENTFORM.NAME')"
    >
      <b-form-input
        id="name"
        :placeholder="$t('POSITIONS.NAME')"
        v-model="$v.form.name.$model"
        :state="validateState('name')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.name.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="code"
      label-cols-lg="2"
      label-for="code"
      :label="$t('JOB.CODE')"
    >
      <b-form-input
        id="code"
        :placeholder="$t('POSITIONS.CODE')"
        v-model="$v.form.code.$model"
        :state="validateState('code')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.code.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="description"
      label-cols-lg="2"
      label-for="description"
      :label="$t('DETAILSFORM.DESC')"
    >
      <b-form-textarea
        id="description"
        v-model="$v.form.description.$model"
        :placeholder="$t('DETAILSFORM.DESC1')"
        :state="validateState('description')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.description.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <div class="d-flex justify-content-end border-top pt-3">
      <b-button type="reset" variant="light" class="mr-2 font-size-h6">
        {{ $t("EMPLOYEES.DETAILS.RESET") }}</b-button
      >

      <button
        ref="employee-general-button"
        class="btn btn-primary text-white font-weight-light font-size-h6"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "PersonalLeaveApplicationEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Leave Pending Application Form",
    },
  },
  data() {
    return {
      form: {
        name: "",
        code: "",
        description: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      code: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(200),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Application Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.formBusy = !this.formBusy;
      this.$store
        .dispatch("submitPendingApplication", this.form)
        .then(() => {
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.formBusy = !this.formBusy));
    },
    uploadPicture() {
      this.imageBusy = !this.imageBusy;
      this.$store
        .dispatch("uploadEmployeeImage", this.image)
        .then((res) => {
          console.log(res.data);
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
          this.image_url = res.data.url;
        })
        .finally(() => (this.imageBusy = !this.imageBusy));
    },
  },
  mounted() {
    this.$store.dispatch("fetchPositionSettingsForm").then((response) => {
      const formData = response.data.data;

      console.log("Employment date", formData.date);
      this.form = {
        id: formData.id,
        name: formData.name,
        code: formData.code,
        description: formData.description,
      };
    });
  },
  computed: {},
};
</script>

<style scoped></style>
