<template>
  <div>
    <b-card v-if="isDev" class="card-custom">
      <div class="d-flex flex-column flex-md-row justify-content-between pb-5">
        <h3 class="font-weight-bolder text-dark">
          {{ $t("JOB.POSITIONS") }}
        </h3>
        <div class="card-toolbar mt-sk mt-lg-0">
          <PositionSettingsPageSidebar></PositionSettingsPageSidebar>
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="refresh"
          >
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
            </span>
          </b-button>
          <b-button
            variant="primary"
            class="font-weight-bolder"
            v-b-toggle.position-settings-sidebar
            >{{ $t("DEPARTMENT.BUTTON") }}</b-button
          >
        </div>
      </div>

      <PositionSettingsTable :reload="reload"></PositionSettingsTable>
    </b-card>
    <b-card v-else-if="isDev" class="card-custom">
      <AppEmptyList
        text-top="Coming soon"
        text-bottom="In this page you'll be able to set company Job Positions"
      ></AppEmptyList>
    </b-card>
  </div>
</template>

<script>
import PositionSettingsPageSidebar from "@/modules/setting/components/positions/PositionSettingsPageSidebar";
import PositionSettingsTable from "@/modules/setting/components/positions/PositionSettingsTable";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
export default {
  name: "PositionSettingsPage",
  components: {
    AppEmptyList,
    PositionSettingsPageSidebar,
    PositionSettingsTable,
  },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    isDev() {
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },

    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
