<template>
  <b-sidebar
    ref="position-settings-sidebar-ref"
    id="position-settings-sidebar"
    backdrop
    right
    lazy
    sidebar-class="offcanvas p-7"
    no-header
    bg-variant="white"
  >
    <template #default="{ hide }">
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bolder m-0">{{ $t("JOB.CREATE") }}</h3>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          v-on:click="hide"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </div>
      <div class="align-items-center">
        <PositionSettingsPageForm></PositionSettingsPageForm>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import PositionSettingsPageForm from "@/modules/setting/components/positions/PositionSettingsPageForm";
export default {
  name: "PositionSettingsPageSidebar",
  components: { PositionSettingsPageForm },
};
</script>

<style scoped></style>
