<template>
  <div>
    <b-table
      id="position-table"
      ref="position-table"
      responsive="sm"
      :fields="fields"
      :items="getDeviceBeacon"
      show-empty
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column mt-1 mb-2">
          <span class="text-dark-75 font-weight-bolder font-size-lg">
            {{ data.item.name }}
          </span>
          <span>{{ data.item.make }}</span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showPosition(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deletePositionDetails"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
      <template #empty="">
        <AppEmptyList
          text-top="All employees verified"
          text-bottom="You have no unverified users. To add new user, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <PositionSettingsModal></PositionSettingsModal>
  </div>
</template>

<script>
//import FacialDeviceTableUserModal from "@/modules/device/components/facial/FacialDeviceTableUserModal";
import PositionSettingsModal from "@/modules/setting/components/positions/PositionSettingsModal";
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import swal from "sweetalert";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "PositionSettingsTable",
  props: ["reload"],
  components: { AppEmptyList, AppLoader, PositionSettingsModal },
  mounted() {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        { key: "beacon", label: this.$t("DEPARTMENTFORM.NAME") },
        { key: "serialnum", label: this.$t("JOB.CODE") },
        { key: "description", label: this.$t("DETAILSFORM.DESC") },
        { key: "actions", label: this.$t("DEVICE.FACIAL.PAGE.ACTIONS") },
      ],
    };
  },
  methods: {
    handlePageChange(value) {
      console.log(value);
      this.$store
        .dispatch("getDepartment", {
          page_number: value,
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    showPosition(row) {
      console.log("Selected Leave ", row);
      this.$bvModal.show("position-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },
    deletePositionDetails() {
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.WARNING"),
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getDeviceBeacon"]),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
    },
  },
};
</script>

<style scoped></style>
