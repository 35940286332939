import { render, staticRenderFns } from "./PositionSettingsPageForm.vue?vue&type=template&id=05f05da0&scoped=true&"
import script from "./PositionSettingsPageForm.vue?vue&type=script&lang=js&"
export * from "./PositionSettingsPageForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f05da0",
  null
  
)

export default component.exports